<template>
  <div class="main-view">
    <el-radio-group
      v-model="table.params.type"
      @change="handleTabs"
      style="margin-bottom: 10px"
    >
      <el-radio-button :label="1">待发放</el-radio-button>
      <el-radio-button :label="2">已处理</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="姓名:" prop="wechat_name">
          <el-input
            clearable
            v-model="table.params.wechat_name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="提现账号:" prop="account">
          <el-input
            clearable
            v-model="table.params.account"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="theneBtn"
            type="primary"
            icon="el-icon-search"
            @click="onSearch"
            >查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button
        size="mini"
        type="primary"
        plain
        icon="el-icon-download"
        @click="handleExport"
        >全部导出</el-button
      >
      <el-button
        size="mini"
        type="primary"
        plain
        icon="el-icon-download"
        @click="handleExportBatch"
        >批量导出</el-button
      >
    </div>

    <VTable
      has-pagionation
      title=""
      addText="添加"
      :field="table.params.type == 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page="table.params.page"
      :pageSize="table.params.count"
      :total="table.total"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:amount="{row}">
        <span>¥ {{row.amount}}</span>
      </template>
      <template v-slot:status="{row}">
        <span v-if="row.status == 1">提现成功</span>
        <el-popover
          v-if="row.status == 2"
          placement="left"
          width="300"
          trigger="click"
          title="">
          <div style="margin: 20px 0;">
            <div style="margin-bottom:10px;">失败原因：</div>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 10}"
              v-model="row.remark"
              :readonly="true">
            </el-input>
          </div>
          <el-button slot="reference" type="text">提现失败</el-button>
        </el-popover>
      </template>
      <template v-slot:action="{ row }">
        <template v-if="table.params.type == 1">
          <el-button
            v-if="row.batch_no == '' && row.desc == ''"
            type="text"
            @click="handleProvide(row)"
            >发放</el-button
          >
          <template v-if="!!row.batch_no && !!row.desc">
            <el-button
              type="text"
              @click="handleProvideNews(row)"
              >重新发放</el-button
            >
            <el-button
              type="text"
              @click="handleFail(row)"
              >标记失败</el-button
            >
          </template>
          <!-- 提现结果查询接口还未返回成功或失败时，离开当前路由终止接口查询时，本条数据需要重新调取提现结果查询接口 -->
          <el-button
            v-if="!!row.batch_no && row.desc == ''"
            type="text"
            @click="handleResule(row)"
            >提现结果查询</el-button
          >
        </template>
      </template>
    </VTable>

  </div>
</template>

<script>
import VTable from "@/components/VTable";
import { mixinTable } from "@/mixins/table.js";
import { exportData } from "@/util";
export default {
  name: "DrawmoneyList",
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "提现人", width:'120', hidden: false },
        { name: "account", label: "提现账号", hidden: false },
        { name: "wechat_name", label: "微信昵称",  hidden: false },
        { name: "amount", label: "提现金额",  hidden: false },
        { name: "true_name", label: "真实姓名", hidden: false },
        { name: "openid", label: "openid", width: "280", hidden: false },
        { name: "appid", label: "appid", width: "180", hidden: false },
        { name: "desc", label: "微信反馈", showTooltip: true, width: "160", hidden: false },
        { name: "create_time", label: "申请时间", width: "160", hidden: false },
        {
          name: "action",
          label: "操作",
          width: "150",
          hidden: false,
        },
      ],
      field2: [
        { name: "name", label: "提现人", width:'120', hidden: false },
        { name: "account", label: "提现账号", hidden: false },
        { name: "wechat_name", label: "微信昵称",  hidden: false },
        { name: "amount", label: "提现金额", hidden: false },
        { name: "true_name", label: "真实姓名", hidden: false },
        { name: "openid", label: "openid", width: "280", hidden: false },
        { name: "appid", label: "appid", width: "180", hidden: false },
        { name: "create_time", label: "申请时间", width: "160", hidden: false },
        { name: "issue_time", label: "处理时间", width: "160", hidden: false },
        {
          name: "status",
          label: "状态",
          width: "100",
          hidden: false,
        },
      ],
      table: {
        loading: false,
        params: {
          wechat_name: "",
          account: "",
          type: 1, // 类型 1:待发放 2:已发放
          page: 1,
          count: 10,
        },
        data: [],
        total: 0,
      },
      delIds: [],
      timer: '',
    };
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http
        .get("/admin/flex_withdraw/list", { params: this.table.params })
        .then((res) => {
          if (res.code === 1) {
            this.table.data = res.data.list;
            this.table.total = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true);
      if (!!row.id) {
        dom.getDetail(row);
      }
      dom = null;
    },
    handleSelectionChange(value) {
      this.delIds = [];
      if (value.length > 0) {
        value.forEach((v) => {
          this.delIds.push(v.id);
        });
      }
    },
    handleExport() {
      let _params = {
        type: this.table.params.type,
        wechat_name: this.table.params.wechat_name,
        account: this.table.params.account,
      };
      exportData(_params, "/admin/flex_withdraw/export");
    },
    // 批量
    handleExportBatch() {
      if (this.delIds.length > 0) {
        let _params = {
          ids: this.delIds.join("|"),
          type: this.table.params.type,
          wechat_name: this.table.params.wechat_name,
          account: this.table.params.account,
        };
        exportData(_params, "/admin/flex_withdraw/export");
      } else {
        this.$message.warning("请选择需要导出的数据");
      }
    },
    // 单条发放
    handleProvide(row) {
      this.$confirm('请确认发放操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.table.loading = true;
        this.$http.post('/admin/flex_withdraw/issue', { id: row.id }).then(res => {
          if(res.code == 1) {
            this.timer = setInterval(() => {
              setTimeout(() => {
                this.resultFun(row.id)
              }, 0)
            }, 1000 );
          }
        })
      }).catch(() => {})
    },
    // 发放失败后重新发放
    handleProvideNews(row) {
      this.$confirm('请确认重新发放操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.table.loading = true;
        this.$http.post('/admin/flex_withdraw/again', { id: row.id }).then(res => {
          if(res.code == 1) {
            this.timer = setInterval(() => {
              setTimeout(() => {
                this.resultFun(row.id)
              }, 0)
            }, 1000 );
          }
        })
      }).catch(() => {})
    },
    resultFun(curId) {
      this.$http.get('/admin/flex_withdraw/withdrawResult', {params: {id: curId} }).then(res => {
        if(res.code == 1) {
          // "status": 1 //状态 1:成功 2:等待结果 3:失败
          if(res.data.status == 1) {
            this.$message.success('操作成功！')
            clearInterval(this.timer)
            this.table.loading = false;
            this.getTable();
          } else if (res.data.status == 3) {
            this.$message.error("操作失败！")
            clearInterval(this.timer)
            this.table.loading = false;
            this.getTable();
          } else if (res.data.status == 2) {
            console.log(res.data.status);
            die;

          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleResule(row) {
      this.timer = setInterval(() => {
        setTimeout(() => {
          this.resultFun(row.id)
        }, 0)
      }, 1000 );
    },
    // 标记失败
    handleFail(row) {
      this.$prompt('请输入失败原因', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.+$/,
        inputErrorMessage: '失败原因为空'
      }).then(({ value }) => {
        this.$http.post('/admin/flex_withdraw/operation', { id: row.id, remark: value }).then(res => {
          if(res.code == 1) {
            this.getTable();
          }
        })
      }).catch(() => {});
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>